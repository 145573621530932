@import "../../../../../style/fonts.scss";
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
p {
  font-family: "Poppins";
}
.Chatroom {
  .LoadingContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    top: calc(50% - 50px);
    .Loading {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  }
  flex: 1;
  display: flex;
  .Vertical {
    padding: 0 20px;
    height: 100%;
    gap: 10px;
  }
}
.selectedParticipant {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #19144d;
  border-radius: 10px;
}
.selectedParticipant video {
  height: 100%;
}
.user{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.selectedParticipant .user img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bigVideoOptions {
  display: flex;
  padding: 0 15px;
  bottom: 15px;
  justify-content: space-between;
  width: 100%;
  position:absolute;
}
.SmallVideosContainer.withCarousel {
  max-width: 100%;
  .Participant {
    width: 100%;
  }

}
.VideoContainer {
  margin: auto;
  height: 100%;
}
.SmallVideosContainer {
  display: flex;
  flex: 1;
  max-width: 100%;
  .Participant {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 90%;
    margin-right: 15px;
    p {
      font-family: "Poppins-300";
    }
    .VideoContainer {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: #19144d;
      border-radius: 8px;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .shareScreenStatus:after {
      content: 'Sharing Screen';
      color: #13ac64;
    }
    .listeningStatus:after {
      content: 'Viewing';
      color: #4a4f5f;
    }
    .voiceStatus:after {
      content: 'Voice';
      color: #4423b6;
    }
    .videoStatus:after {
      content: 'Video';
      color: #4423b6;
    }
    .voiceVideoStatus:after {
      content: 'Voice & Video';
      color: #4423b6;
    }
    .pendingToJoinStatus:after {
      content: 'Pending To Join...';
      color: #db9818;
    }
  }
}
.OptionsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  .ParticipantCounter {
    border-radius: 8px;
    border: solid 1px #4423b6;
    width: 256.5px;
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-left: 12.5px;
      font-family: "Poppins-500";
      font-size: 14px;
    }
  }
}
.BigButtonsContainer {
  display: flex;
  width: 100%;
  height: 48px;
  gap: 10px;
}
.MeetingScreensContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
}
.MeetingScreens {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 176px;
  gap: 15px;
}
.MeetingScreens.chat {
  width: 70%;
}
.MeetingChatContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color:  #f1f2f7;
  border-radius: 8px;
  gap: 5px;
}
.MeetingChatTabs {
  display: flex;
}
.MeetingChatTabs .tab {
  border: 0;
  width: 100%;
  background: #e6e8f1;
  padding:20px 0;
  display: flex;
  justify-content: center;
  gap: 5px;
  font-family: Poppins-300;
  font-size: 16px;
}
.MeetingChatTabs .tab svg,
.MeetingChatTabs .tab path {
  max-height: 20px;
  max-width: 20px;
}
.MeetingChatTabs .tab.active {
  background: #4423b6;
  color: #fff;
}
.MeetingChatTabs .tab:first-child {
  border-top-left-radius: 6px;
}
.MeetingChatTabs .tab:last-child {
  border-top-right-radius: 6px;
}
.ChatMessagesContainer {
  height: 63%;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 10px;
}
.messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.firstLineMessage {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.messageContent {
  width: 100%;
}
.messageUserName {
  font-family: "Poppins";
  font-size: 16px;
}
.message {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
}
.messageText {
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
}
.currentUser {
  color: #4423b6;
}
.message.new {
  background: #dee7f5;
}
.writeMessageTextArea {
  width: 100%;
  min-height: 45px;
  padding: 0 9px;
  flex: 1;
  height: 100%;
}
.writeMessageTextArea textarea {
  border: 1px solid #d5d7e2;
  resize: none;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  padding: 10px;

}
.writeMessageTextArea textarea:focus-visible  {
  outline: transparent auto 0px !important;
  border: 1px solid  #4423b6;
}
.Name {
  position: absolute;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 15px;
  padding: 0 15px;
  border-radius: 14px;
  left: 15px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}
.VideoContainer .Name {
  bottom: 0;
}
.individual-card-container {
  width: 25%;
}
.fullScreenButton button,
.fullScreenButton button:hover{
  border: unset
}
@media screen and (max-width: 490px){
  .writeMessageTextArea textarea {
    font-size: 16px;
    font-family: 'Poppins-200', 'sans-serif';
  }
  .BigButtonsContainer {
    flex-direction: column;
    height: unset;
  }
  .Chatroom {
    .Vertical {
      padding: 20px 20px 0;
      height: 100%;
      gap: 10px;
    }
    .MeetingScreensContainer {
      overflow: hidden;
      gap: 0;
      justify-content: start;
    }
    .ChatMessagesContainer {
      height: 50% !important;
    }
    .SmallVideosContainer.withCarousel {
      .Participant {
        width: 100%;
      }
    }
    .SmallVideosContainer {
      gap: 15px;
      .Participant {
        width: 50%;
        margin-right: 0;
      }
    }

  }

}