.Disclaimer {
  margin: 20px 0;
  letter-spacing: .25pt;
  font-family: "Poppins";
  font-weight: 400;
  h1 {
    font-size: 2em;
    letter-spacing: .25pt;
    font-weight: 600;
  }
  h2 {
    font-size: 1.5em;
  }
}