.betaLogo {
  svg {
    width: 78px;
  }
}

.titleBarContainer {
  border-bottom: 1px solid #e6e6e6;
}
%control-button {
  border-radius: 9px;
  border: 1px solid #4423b6;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4423b6;
  height: 32px;
  padding: 0px 5px;
  transition-duration: 300ms;
  stroke-width: 2px;
  stroke-linecap: round;
  p {
    padding: 0;
    margin: 0;
  }
  &:hover {
    color: white;
    background-color: #4423b6;
    cursor: pointer;
    transition-duration: 300ms;
  }
}
.controlButton {
  @extend %control-button;
}
.controlButtonDanger {
  @extend %control-button;
  border: 1px solid red;
  color: red;
  &:hover {
    color: white;
    background-color: red;
  }
}