.betaLogo {
  position: relative;
  .beta {
    position: absolute;
    top: 20px;
    padding: 0 5px 0 5px;
    left: 65px;
    color: white;
    font-weight: 600;
    background-image: linear-gradient(to right, #825ef6, #7456e6, #674dd6, #5a45c7, #4d3db7, #3b4cbf, #2659c5, #0064c9, #0084da, #00a1df, #00bbdc, #42d3d8);
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    p {
      font-size: 10px;
      padding: 0;
      margin: 0;
    }
    &:hover {
      cursor: pointer;
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px, rgb(51, 51, 51) 0px 0px 0px 1px;
    }
  }
}