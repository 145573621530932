.authenticationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  .authenticationHeader {
    height: 140px;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    svg {
      width: 200px;
    }
    p {
      font-size: 14px;
    }
  }
  .authenticationWindow {
    width: 402px;
    max-width: 98%;
    margin: 10px;
    padding: 20px 29px 10px 29px;
    border-radius: 20px;
    background-color: #fff;
    color: black;
    h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .loaderScreen, .successScreen, .errorScreen {
      display: flex;
      flex-direction: column;
      height: 100%;
      user-select: none;
      .spacer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        gap: 12px;
      }
      svg {
        width: 100px;
        height: 100px;
      }
    }
    .successScreen .spacer {
      color: lime;
      p { color: black }
    }
    .errorScreen .spacer {
      color: red;
      p { color: black }
    }
    .loaderScreen {
      .authenticationLoader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .loader {
          position: relative;
          width: 85px;
          height: 50px;
          background-repeat: no-repeat;
          background-image: linear-gradient(#4423b6 50px, transparent 0),
                            linear-gradient(#4423b6 50px, transparent 0),
                            linear-gradient(#4423b6 50px, transparent 0),
                            linear-gradient(#4423b6 50px, transparent 0),
                            linear-gradient(#4423b6 50px, transparent 0),
                            linear-gradient(#4423b6 50px, transparent 0);
          background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
          animation: rikSpikeRoll 0.65s linear infinite alternate;
        }
        @keyframes rikSpikeRoll {
          0% { background-size: 10px 3px;}
          16% { background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
          33% { background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
          50% { background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px}
          66% { background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px}
          83% { background-size: 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px, 10px 3px}
          100% { background-size: 10px 3px, 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px}
        }
          
      }
    }
  }
}