@import "style/colors.scss";
.userControlContainer {
  position: relative;
  width: 34px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  .userControl {
    background-color: white;
    border: 1px solid $colors-main;
    border-radius: 9px;
    color: $colors-main;
    box-sizing: border-box;
    overflow: hidden;
    &:hover {
      color: white;
      cursor: pointer;
      transition-duration: 200ms;
      background-color: $colors-main;
    }
    svg {
      width: 30px;
      height: 30px;
      stroke-width: 1.6px;
    }
  }
  .userControlOpen { 
    background-color: $colors-main;
    color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:hover {
      transition-duration: 100ms;
    }
  }
  %userControlMenu {
    overflow: hidden;
    position: absolute;
    top: 32px;
    right: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $colors-main;
    border-radius: 9px;
    border-top-right-radius: 0;
    padding: 5px;
    width: 130px;
    background-color: $colors-main;
    height: fit-content;
    z-index: 100;
    a {
      user-select: none;
      &:hover {
        border-radius: 0;
        user-select: none;
      }
    }
  }
  .userControlMenu, .userControlMenuAuthenticated {
    @extend %userControlMenu;
  }
  %userControlMenu-enter {
    height: 0px;
    width: 35px;
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    a {
      pointer-events: none;
    }
  }
  %userControlMenu-enter-active {
    transition-duration: 100ms;
    transform: translateY(0px);
    height: 114px;
    width: 130px;
    opacity: 1;
  }
  %userControlMenu-exit {
    transition-duration: 100ms;
    transform: translateY(0px);
    height: 114px;
    width: 130px;
    opacity: 1;
  }
  %userControlMenu-exit-active {
    height: 0px;
    width: 35px;
    transform: translateY(-10px);
    opacity: 0;
  }
  .userControlMenu-enter, .userControlMenuAuthenticated-enter {
    @extend %userControlMenu-enter;
  }
  .userControlMenu-enter-active, .userControlMenuAuthenticated-enter-active {
    @extend %userControlMenu-enter-active;
  }
  .userControlMenuAuthenticated-enter-active {
    height: 70px;
  }
  .userControlMenu-exit, .userControlMenuAuthenticated-exit {
    @extend %userControlMenu-exit;
  }
  .userControlMenuAuthenticated-exit {
    height: 70px;
  }
  .userControlMenu-exit-active, .userControlMenuAuthenticated-exit-active {
    @extend %userControlMenu-exit-active;
  }
}