.windowLayout {
  max-width: 1126px;
  margin: 10px 0;
  min-height: 90vh;
}
.windowLayoutContent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}