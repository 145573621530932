$checkbox-size: 20px;
.container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    .checkBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: 4px;
        background-color: #d5d5d5;
    }
}
