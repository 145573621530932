.roomsTitle {
  font-size: 31px;
}
.attemptJoinRoom {
  align-items: center;
}
.createRoom, .joinRoom {
  height: 390px !important;
  overflow: hidden !important;
}
.centerRooms {
  margin-top: calc(45vh - (400px / 2));
}
@media only screen and ((max-height: 950px) or (max-width: 607px)) {
  .centerRooms {
    margin-top: 30px;
  }
}