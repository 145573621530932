.layout {
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  .header {
    width: 100%;
  }
  .layoutContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    color: rgb(121, 81, 255);
    font-size: 13px;
    p {
      margin: 0;
    }
  }
}