.containerChatRoom {
  flex: 1;
  // padding: 15px 15px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0.3fr;
  grid-auto-flow: row;
  grid-template-areas:
    "Video Video Video Video"
    "Video Video Video Video"
    "Video Video Video Video"
    "Guests Guests Guests Guests"
    "Options Options Options Options";
  gap: 15px;
  height: 84vh;
}
.containerChatRoom.chatActive {
  grid-template-areas:
    "Video Video Video Chat"
    "Video Video Video Chat"
    "Video Video Video Chat"
    "Guests Guests Guests Chat"
    "Options Options Options Options" ;
}
.Video {
  grid-area: Video;
  display: flex;
  min-height: 20vh;
}

.Chat {
  display: flex;
  grid-area: Chat;
}

.Guests {
  display: flex;
  grid-area: Guests;
  height: 100%;
  min-height: 20vh;
  max-height: 20vh;
}

.Options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 15px;
  grid-auto-flow: row;
  grid-template-areas:
    "meetingSettings meetingSettings meetingSettings";
  grid-area: Options;
}

.devices { grid-area: devices; }

.meetingSettings { grid-area: meetingSettings; }
.container-icon-collapse {
  display: none;
}
@media screen and (max-width: 490px){
  .container-icon-collapse {
    display: flex;
    justify-content: center;
  }

  .containerChatRoom {
    height: 82svh;
  }
  .Video {
    grid-area: Video;
    display: flex;
    min-height: 20svh;
  }

  .Chat {
    display: flex;
    grid-area: Chat;
    //max-height: 40svh;
  }

  .Guests {
    display: flex;
    grid-area: Guests;
    height: 100%;
    min-height: 20svh;
    max-height: 20svh;
  }
  .containerChatRoom.chatActive {
    grid-template-areas:
    "Chat Chat Chat Chat"
    "Chat Chat Chat Chat"
    "Chat Chat Chat Chat"
    "Guests Guests Guests Guests"
    "Options Options Options Options" !important;
  }
  .containerChatRoom.chatActive .Video {
    display: none;
  }
  .Options {
    grid-template-rows:unset;
  }
}