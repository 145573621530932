.connectingScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  max-width: 100%;
  padding: 10px;
  .connectingScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 15px;
    }
    .merge {
      position: relative;
      margin-bottom: 10px;
      width: 300px;
      max-width: 100%;
      height: 150px;
      .loader {
        position: absolute;
        top: 42px;
        left: 126px;
        border: 24px solid;
        border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: animloader 1s linear infinite;
      }
    
      @keyframes animloader {
        0% {
          border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75);
        }
        33% {
          border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35);
        }
        66% {
          border-color: rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25);
        }
        100% {
          border-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .connectingScreenContainer {
    .connectingScreen {
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 10px;
      }
      .merge {
        max-width: 200px;
        height: 100px;
        .loader {
          top: 30px;
          left: 85px;
          border: 15px solid;
        }
      }
    }
  }
}