@keyframes slide
{
    0%   {
      font-size: 16px;
      top: 10px;
    }
    100% {
      font-size: 13px;
      top: 5px;
    }
}
.inputWrapper {
  width: 100%;
  background-color: #f1f2f7;
  border-radius: 5px;
  margin-bottom: 15px;
  .inputContainer {
    position: relative;
    height: 47px;
    label {
      position: absolute;
      left: 10px;
      top: 12px;
      font-weight: 500;
      color: #4a4f5f;
      z-index: 1;
    }
    .selected {
      font-size: 13px;
      top: 5px;
      animation: slide 0.2s linear;
    }
    input {
      padding: 13px 0 0 10px;
      background-color: transparent;
      position: absolute;
      outline: none;
      border: 0;
      width: 100%;
      height: 48px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .inputError {
    padding: 0 0 0 10px;
    margin: 0;
  }
}
.errorsPresent {
  border: 1px solid red;
  .inputContainer {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
  .inputError {
    padding: 0 0 0 5px;
    display: flex;
    align-items: center;
    color: orange;
    svg {
      $svgsize: 13px;
      width: $svgsize;
      height: $svgsize;
      margin-right: 7px;
    }
    p {
      color: black;
      font-size: 13px;
      margin: 0;
    }
    &:nth-child(odd) {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }
}
