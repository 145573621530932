.maintenanceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  .merge {
    position: relative;
    width: 300px;
    .logoContainer {
      position: relative;
      svg {
        width: 280px;
      }
    }
    .robot {
      position: absolute;
      top: 46px;
      left: 150px;
      transform: scale(-1,1);
      color: rgba($color: white, $alpha: 0.7);
      width: 80px;
      height: 80px;
      z-index: 2;
      @keyframes robotMove
      {
          0%   {
            transform: scale(1,1);
            left: 0;
          }
          49% {transform: scale(1,1);}
          50% {left: 150px; transform: scale(-1,1);}
          51% {transform: scale(-1,1);}
          100% {
            left: 150px;
            transform: scale(-1,1);
          }
      }
      animation: robotMove 5s linear;
    }
  }
}
@media only screen and (max-width: 500px) {
  .maintenanceContainer {
    p {
      font-size: 13px;
    }
  }
}